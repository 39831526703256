.CardInner,
.Info,
.Info > div {
  width: 100%;
}

.CardInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.InfoButton {
  display: flex;
  align-items: center;
  gap: 4px;
}

.InfoButton > svg {
  width: 16px;
  flex-shrink: 0;
}

.Buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
