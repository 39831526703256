.Hero {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Cards {
  width: 100%;
  display: flex;
  flex-direction: column;
}