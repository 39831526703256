.Section {
  width: 100%;
  max-width: 1236px;
  padding: 0px 45px;
  margin: 0 auto 160px;
}

.Content {
  width: 100%;
  padding: 48px 48px 0;
  border-radius: 48px;
  background: linear-gradient(117.06deg, #d9eaff 5.67%, #fff3d8 49.83%, #e9d8ff 94.48%),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 38px;
}

.TopBlock {
  width: 100%;
  max-width: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}

.TopBlock p {
  color: var(--black-800);
}

.ImageBlock {
  width: 43%;
  max-width: 464px;
  display: flex;
}

.ImageBlock img {
  width: 100%;
}

.FirstCoin,
.SecondCoin,
.ThirdCoin,
.FourthCoin {
  width: auto;
  position: absolute;
  height: auto;
}

.FirstCoin {
  left: 40px;
  bottom: 128px;
}

.SecondCoin {
  right: 40px;
  bottom: 128px;
}

.ThirdCoin {
  left: 160px;
  bottom: 53px;
}

.FourthCoin {
  right: 160px;
  bottom: 53px;
}

@media (max-width: 1023px) {
  .Section {
    padding: 0px 32px;
    margin: 0 auto 132px;
  }

  .ImageBlock {
    width: 100%;
    max-width: 380px;
    display: flex;
  }

  .FirstCoin,
  .SecondCoin,
  .ThirdCoin,
  .FourthCoin {
    display: none;
  }
}

@media (max-width: 767px) {
  .Section {
    padding: 0px 8px;
    margin: 0 auto 48px;
  }

  .TopBlock button,
  .TopBlock a {
    width: 100%;
  }

  .Content {
    width: 100%;
    padding: 48px 16px 0;
    gap: 24px;
    border-radius: 16px;
  }
}
