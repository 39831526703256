.Wrapper {
  position: relative;
}

.Headline {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
}

