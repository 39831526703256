.HeaderWrapper {
  width: 100%;
  max-width: 1260px;
  padding: 0 45px;
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  background: transparent;
  position: relative;
}

.HeaderInner {
  width: 100%;
  display: grid;
  grid-template-columns: 141px 1fr auto;
  gap: 32px;
  align-items: center;
}

.LogoBlock,
.LogoBlock a {
  width: 100%;
  max-width: 141px;
  display: flex;
  flex-shrink: 0;
}

.LogoBlock svg:last-child {
  display: none;
}

.ButtonsWrap {
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
}

.MobileButton {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: none;
  background: url("../../../../assets/img/icons/menu-icon.svg") no-repeat center;
  background-size: 48px;
}

.Open {
  background: url("../../../../assets/img/icons/close.svg") no-repeat center;
  background-size: 16px;
}

@media (max-width: 1439px) {
  .HeaderWrapper {
    padding: 0 20px;
  }

  .HeaderInner {
    grid-template-columns: 132px 1fr auto;
    gap: 24px;
  }
}

@media (max-width: 1023px) {
  .HeaderInner {
    grid-template-columns: 132px 1fr;
  }

  .ButtonsWrap {
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 20px 20px;
    justify-content: flex-end;
    width: 100%;
    background: var(--white-500);
    backdrop-filter: blur(20px);
  }
}

@media (max-width: 767px) {
  .HeaderWrapper {
    padding: 0 16px;
  }

  .HeaderInner {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .LogoBlock {
    max-width: 24px;
  }

  .LogoBlock svg:first-child {
    display: none;
  }

  .LogoBlock svg:last-child {
    display: block;
  }

  .WrapperOpen {
    margin-bottom: 164px;
  }

  .MobileButton {
    display: block;
  }
}
