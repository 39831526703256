.PoolsBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.PoolsPosition {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.PoolsPosition > p {
  color: var(--black-500);
}
