.Wrapper {
  width: 100%;
}

.Values {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: var(--black-500);
}

.Values span {
  color: var(--black)
}

.Values > svg {
  transform: rotate(-90deg);
  width: 24px;
}
