.Wrapper {
  position: relative;
}

.Wrapper > div:nth-child(2) {
  color: var(--black-500);
}

.Headline {
  width: 100%;
}

.Settings {
  position: absolute;
  top: 56px;
  right: 15px;
}


.DoubleButtons {
  width: 100%;
  display: grid;
  align-items: flex-start;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 767px) {
  .DoubleButtons {
    grid-template-columns: 1fr;
  }
}