.Section {
  width: 100%;
  max-width: 1236px;
  padding: 0px 45px;
  margin: 0 auto 160px;
}

.CardWrapper {
  width: 100%;
  align-items: flex-start;
  border-radius: 48px;
  display: flex;
  gap: 30px;
  background: linear-gradient(117.06deg, #d9eaff 5.67%, #fff3d8 49.83%, #e9d8ff 94.48%),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
}

.TextBlock {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 48px 0 48px 48px;
}

.TextBlock h2 {
  margin-bottom: 16px;
}

.TextBlock p {
  color: var(--black-800);
  margin-bottom: 24px;
}

.ImageBlock {
  width: 100%;
  padding: 24px 0;
  max-width: 362px;
}

.Buttons {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

@media (max-width: 1023px) {
  .Section {
    padding: 0px 32px;
    margin: 0 auto 132px;
  }

  .CardWrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .TextBlock {
    padding: 0 32px 32px;
  }
}

@media (max-width: 767px) {
  .Section {
    padding: 0px 8px;
    margin: 0 auto 48px;
  }

  .ImageBlock {
    padding: 0;
  }

  .CardWrapper {
    gap: 24px;
    border-radius: 16px;
  }

  .TextBlock {
    padding: 0 16px 24px;
  }

  .TextBlock p {
    margin-bottom: 16px;
  }

  .Buttons button,
  .Buttons a {
    width: 100%;
  }

  .Buttons {
    gap: 16px;
  }
}
