.Section {
  width: 100%;
  max-width: 1236px;
  padding: 0px 45px;
  margin: 0 auto 160px;
}

.Content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: stretch;
  gap: 30px;
}

.TextBlock {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 24px;
}

.TextBlock h2 {
  margin-bottom: 16px;
}

.TextBlock p {
  color: var(--black-800);
  margin-bottom: 24px;
}

.ImageBlock {
  width: 100%;
  padding: 47px 47px 0;
  border-radius: 46px;
  background-color: var(--purple);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.Buttons {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

@media (max-width: 1023px) {
  .Section {
    padding: 0px 32px;
    margin: 0 auto 132px;
  }

  .Content {
    display: flex;
    flex-direction: column-reverse;
  }

  .TextBlock {
    padding-top: 0;
  }

  .ImageBlock img {
    max-width: 380px;
  }
}

@media (max-width: 767px) {
  .Section {
    padding: 0px 8px;
    margin: 0 auto 48px;
  }

  .Content {
    gap: 16px;
  }

  .ImageBlock {
    padding: 26px 26px 0;
    border-radius: 16px;
  }

  .TextBlock h2 {
    padding: 0 8px;
  }

  .TextBlock p {
    margin-bottom: 16px;
    padding: 0 8px;
  }

  .Buttons button,
  .Buttons a {
    width: 100%;
  }

  .Buttons {
    gap: 16px;
  }
}
