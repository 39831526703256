.Section {
  width: 100%;
  max-width: 1236px;
  padding: 0px 45px;
  margin: 0 auto 160px;
}

.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.TopCard {
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 30px;
  border-radius: 48px;
  background: var(--orange);
  overflow: hidden;
}

.ImageBox {
  width: 100%;
  display: flex;
  max-width: 362px;
}

.ImageBox img {
  object-fit: cover;
}

.TopDescriptionBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 48px 48px 0;
}

.TopDescriptionBox h2 {
  margin-bottom: 16px;
}

.TopDescriptionBox p {
  color: var(--black-800);
  margin-bottom: 16px;
}

.TopDescriptionBox p a {
  color: var(--black-800);
  text-decoration: underline;
}

.Buttons {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
}

.DoubleCards {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: stretch;
  gap: 30px;
}

.MiniCard {
  width: 100%;
  padding: 32px;
  display: flex;
  align-items: flex-start;
  border-radius: 48px;
  background-color: var(--black-30);
  gap: 16px;
  flex-direction: column;
}

.MiniCard > p > a {
  text-decoration: underline;
}

.MiniCard p {
  color: var(--black-800);
}

.LinksList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: flex-start;
}

.QuestionLink {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Links {
  width: 100%;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.Link {
  display: flex;
  align-items: center;
}

.Link svg,
.QuestionLink svg {
  transform: rotate(-90deg);
  width: 24px;
  flex-shrink: 0;
}

@media (max-width: 1023px) {
  .Section {
    padding: 0px 32px;
    margin: 0 auto 132px;
  }

  .TopCard {
    flex-direction: column;
    align-items: center;
  }

  .TopDescriptionBox {
    padding: 0 32px 32px;
  }

  .DoubleCards {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .Section {
    padding: 0px 8px;
    margin: 0 auto 48px;
  }

  .TopCard {
    gap: 24px;
    border-radius: 16px;
  }

  .Content {
    gap: 8px;
  }

  .TopDescriptionBox {
    padding: 0 16px 24px;
  }

  .TopDescriptionBox p {
    margin-bottom: 16px;
  }

  .Buttons button,
  .Buttons a {
    width: 100%;
  }

  .Buttons {
    gap: 16px;
  }

  .MiniCard {
    padding: 24px 16px;
    border-radius: 16px;
  }

  .DoubleCards {
    gap: 8px
  }
}
