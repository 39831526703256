.Section {
  width: 100%;
  max-width: 1236px;
  padding: 0px 45px;
  margin: 0 auto 160px;
}

.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.TopCard {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  border-radius: 48px;
  background: linear-gradient(117.06deg, #d9eaff 5.67%, #fff3d8 49.83%, #e9d8ff 94.48%),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
}

.FarmingImage {
  width: 100%;
  display: flex;
  max-width: 362px;
}

.FarmingTextBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 48px 48px 48px 0;
}

.FarmingDescription {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.FarmingDescription p {
  color: var(--black-800);
}

.DoubleCards {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: stretch;
  gap: 30px;
}

.TokensCard {
  width: 100%;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  background-color: var(--black-30);
}

.TokensCard img {
  width: 100%;
  max-width: 384px;
}

.MiniCard {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  border-radius: 48px;
  background-color: var(--black-30);
}

.CoinsImage {
  width: 100%;
  max-width: 200px;
  display: flex;
}

.MiniCardInner {
  width: 100%;
  padding: 32px 48px 32px 0;
}

.MiniCardInner h4 {
  margin-bottom: 8px;
}

.MiniCardInner p {
  margin-bottom: 16px;
  color: var(--black-800);
}

.Links {
  width: 100%;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.Link {
  display: flex;
  align-items: center;
}

.Link svg {
  transform: rotate(-90deg);
  width: 24px;
  flex-shrink: 0;
}

@media (max-width: 1023px) {
  .Section {
    padding: 0px 32px;
    margin: 0 auto 132px;
  }

  .DoubleCards {
    grid-template-columns: 1fr;
  }

  .TopCard {
    flex-direction: column;
    padding: 32px;
  }

  .FarmingTextBlock {
    padding: 0;
  }

  .CoinsImage {
    max-width: 172px;
  }
}

@media (max-width: 767px) {
  .Section {
    padding: 0px 8px;
    margin: 0 auto 48px;
  }

  .TopCard {
    flex-direction: column;
    padding: 0;
    gap: 24px;
    border-radius: 16px;
  }

  .FarmingTextBlock {
    padding: 0 16px 24px;
    gap: 16px;
  }

  .FarmingTextBlock button,
  .FarmingTextBlock a {
    width: 100%;
  }

  .FarmingDescription {
    gap: 16px;
  }

  .Content {
    width: 100%;
    gap: 8px;
  }

  .DoubleCards {
    gap: 24px;
  }
  
  .TokensCard {
    padding: 32px 0;
    overflow: hidden;
    border-radius: 16px;
  }
  
  .TokensCard img {
    flex-shrink: 0;
    width: 384px;
  }
  
  .MiniCard {
    width: 100%;
    padding: 24px 16px;
    border-radius: 16px;
  }

  .MiniCardInner {
    padding:  0;
  }
  
  .CoinsImage {
    display: none;
  }
}
