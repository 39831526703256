.Wrapper {
  position: relative;
}

.Wrapper > div:nth-child(2) {
  color: var(--black-500);
}

.Headline {
  width: 100%;
}

.Settings {
  position: absolute;
  top: 56px;
  right: 15px;
}
