.Wrapper {
    margin: 0 auto;
}

.FormCard > p:first-child {
    text-transform: capitalize;
}

.InputBox {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    gap: 8px;
}

.Input {
    color: var(--black);
}

.Input::placeholder {
    color: var(--black-200);
}

.TokenBox {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    padding: 4px 16px 4px 4px;
    background-color: var(--white);
    border-radius: 26px;
}

.TokenBox img {
    width: 40px;
}

.InfoWrap,
.CardInner {
    width: 100%;
}

.Rewards {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
}

.Wrapper > span {
    width: 100%;
}
