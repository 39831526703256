.ConfirmModal,
.Info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ConfirmModal {
  margin-top: 16px;
}

.Info {
  position: relative;
}

.CenterIcon {
  position: absolute;
  display: flex;
  left: calc(50% - 16px);
  top: calc(50% - 20px);
  padding: 4px 8px;
  border: 1px solid var(--black-100);
  border-radius: 16px;
  background-color: var(--white);
  width: 42px;
  height: 32px;
}

.CenterIcon > svg {
  rotate: 90deg;
}

.Value h3 {
  overflow: auto;
}
