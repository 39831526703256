.Section {
  width: 100%;
  max-width: 1236px;
  padding: 0px 45px;
  margin: 0 auto 160px;
}

.Container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 30px;
}

.TextCard {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 24px;
}

.TextCard h2 {
  margin-bottom: 16px;
}

.TextCard p {
  color: var(--black-800);
  margin-bottom: 24px;
}

.ImageCard {
  width: 100%;
  border-radius: 48px;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.ImageCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Buttons {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

@media (max-width: 1023px) {
  .Section {
    padding: 0px 32px;
    margin: 0 auto 132px;
  }

  .Container {
    flex-direction: column-reverse;
    display: flex;
  }

  .TextCard {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .Section {
    padding: 0px 8px;
    margin: 0 auto 48px;
  }

  .Container {
    gap: 16px;
  }

  .TextCard h2 {
    padding: 0 8px;
  }

  .TextCard p {
    margin-bottom: 16px;
    padding: 0 8px;
  }

  .Buttons button,
  .Buttons a {
    width: 100%;
  }

  .Buttons {
    gap: 16px;
  }

  .ImageCard {
    border-radius: 16px;
  }
}
