.HeaderWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Header {
  width: 100%;
  max-width: 1236px;
  padding: 20px 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LogoBox {
  width: 100%;
  max-width: 141px;
  display: flex;
}

.LogoBox a {
  width: 100%;
  display: flex;
}

@media (max-width: 1023px) {
  .Header {
    padding: 20px 32px;
  }
}

@media (max-width: 767px) {
  .Header {
    padding: 20px 8px;
  }
}