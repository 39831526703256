.Section {
  width: 100%;
  max-width: 1236px;
  padding: 0px 45px;
  margin: 0 auto 160px;
}

.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
  text-align: left;
}

.PartnersBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 480px;
  padding: 20px;
  border-radius: 48px;
  background-color: var(--black-30);
}

.PartnersLinks {
  display: flex;
  max-width: 890px;
  width: 100%;
  justify-content: center;
  gap: 16px 30px;
  flex-wrap: wrap;
}

.Link {
  width: 100%;
  max-width: 200px;
  display: flex;
  height: 80px;
}

@media (max-width: 1023px) {
  .Section {
    padding: 0px 32px;
    margin: 0 auto 132px;
  }
}

@media (max-width: 767px) {
  .Section {
    padding: 0px 8px;
    margin: 0 auto -24px
  }

  .Container {
    gap: 16px;
  }

  .PartnersBlock {
    padding: 16px;
    border-radius: 16px;
  }

  .PartnersLinks {
    gap: 8px 24px;
  }
}
