.Row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Setting {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.Row input {
  padding: 2.5px 8px;
  max-width: 64px;
}

.Row input::placeholder {
  color: var(--black-500);
}

.Row > button {
  background-color: var(--black-100);
  padding: 2.5px 8px;
}

.SlipageValue {
  color: var(--black-500);
  border: 1px solid var(--black-100);
  padding: 2.5px 12px;
  width: 64px;
  border-radius: 12px;
}
