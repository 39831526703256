.NavigationBlock {
  width: 100%;
  display: flex;
}

.NavigationList {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: center;
}

.NavigationList a:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .NavigationBlock {
    display: none;
  }

  .ShowMenu {
    display: block;
  }

  .NavigationList {
    flex-direction: column;
    align-items: flex-start;
  }
}
