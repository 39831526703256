.TopSide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px 0;
}

.Copyrights,
.Social {
  position: absolute;
}

.Copyrights {
  left: 136px;
}

.Social {
  right: 136px;
  display: flex;
  gap: 24px;
}

.Links {
  display: flex;
  gap: 32px;
  align-items: center;
}

.Social a {
  display: block;
  width: 24px;
  height: 24px;
}

.BottomSide {
  background-color: var(--black);
  color: var(--white);
  padding: 6px 136px;
}

@media (max-width: 1199px) {
  .TopSide {
    flex-direction: column-reverse;
    gap: 24px;
  }
  .Links {
    flex-direction: column;
    gap: 24px;
  }

  .Copyrights {
    position: static;
  }

  .Social, .BottomSide > p:last-child {
    display: none;
  }

  .BottomSide{
    padding: 6px 8px;
    justify-content: center;
  }

  .BottomSide > p {
    margin: 0 auto;
    text-align: center;
  }
}

@media (max-width: 1023px) {
  .Footer {
    padding-bottom: 80px;
  }

  .LandingFooter {
    padding-bottom: 0;
  }
}
