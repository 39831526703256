.Btns {
  display: flex;
  gap: 16px;
  width: 100%;
}

.Btns > button {
  flex-shrink: 1;
}

.Earned {
  margin-bottom: 16px;
}
