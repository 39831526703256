.ButtonsGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 16px;
}

.Wrapper {
  width: 100%;
  padding: 16px;
  display: grid;
  grid-template-columns: 32px 1fr 24px;
  align-items: center;
  text-align: left;
  border-radius: 12px;
  color: var(--black);
  background-color: var(--black-30);
  border: 1px solid var(--black-30);
  transition: 0.3s background-color, 0.3s border;
  gap: 8px;
}

.Wrapper > svg {
  rotate: -90deg;
}

.Wrapper img {
  transform: rotate(-90deg);
  width: 13px;
}

.Wrapper > svg > path {
  fill: var(--black);
}

.Wrapper:hover {
  background-color: var(--black-60);
  border-color: var(--black-100);
}

.Logo {
  width: 32px;
}

.Logo img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
