@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.Processing {
    display: flex;
    align-items: center;
    gap: 12px;
}

.Title {
    color: var(--black-500);
}

.Processing > svg {
    flex-shrink: 0;
    width: 24px;
    animation: spin 2.5s linear infinite;
}

.Processing path {
    stroke: var(--black-500);
}

.Wrapper {
    margin: 0 auto;
}

.CardWrap {
    width: 100%;
}

.InfoRow,
.TextInfoRow {
    width: 100%;
    align-items: flex-start;
    gap: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.TextInfoRow > p {
    display: flex;
    align-items: center;
}

.InfoRow:nth-child(2) {
    align-items: flex-end;
}

.ClaimButton {
    margin-left: auto;
}

.InfoBox {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}


.TokenBox {
    display: flex;
    gap: 16px;
    align-items: center;
}

.TokenImage {
    width: 40px;
    display: flex;
}

.TokenImage img {
    width: 100%;
    height: auto;
}

.BottomBlock {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
}

.DoubleInfos {
    align-items: flex-start;
    gap: 24px;
    display: flex;
}

.LockedCard > p {
    margin-bottom: 4px;
}

.LockedInfos {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 12px;
}

.LochedInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.LockedTokenBlock {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
}

.LockedToken {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 8px;
}

.LockedToken img {
    width: 32px;
}

.LockedRightSide {
    width: fit-content;
    display: flex;
}

@media (max-width: 767px) {
    .InfoRow {
        gap: 8px;
        grid-template-columns: 1fr;
    }

    .TextInfoRow {
        gap: 8px;
    }

    .InfoRow:nth-child(2) {
        align-items: unset;
    }

    .ClaimButton {
        margin-left: unset;
        width: 100%;
    }

    .InfoBox:nth-child(2) {
        margin-top: 16px;
    }

    .BottomBlock {
        flex-direction: column;
    }

    .DoubleInfos {
        gap: 8px;
        grid-template-columns: 1fr 1fr;
    }
}
