.ModalInner,
.TokensList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ModalInner {
  padding-top: 16px;
}

.TokensList {
  max-height: 380px;
  overflow-y: auto;
}

.TokensList::-webkit-scrollbar {
    width: 0;
}
